<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="highlighted-color1">
          <span>{{ $t('pixels') }}</span>
        </h1>
      </div>
      <div class="d-flex">
        <Button
          v-b-modal="'upsert-pixel-modal'"
          class="btn btn-primary"
        >
          {{ $t('new_pixel') }}
        </Button>
        <UpsertPixelModal
          @pixel-updated="handleUpsert"
          @pixel-created="handleUpsert"
        />
      </div>
    </div>
    <!-- PIXELS -->
    <div class="mt-4">
      <PixelListTable
        :reload="reload"
      />
    </div> <!-- END PIXELS -->
  </div>
</template>

<script>

import { mapState } from 'vuex'

import PixelListTable from '@/components/pixels/PixelListTable'
import Button from '@/components/common/Button/Button'
import UpsertPixelModal from '@/components/pixels/UpsertPixelModal'

export default {
  components: {
    Button,
    PixelListTable,
    UpsertPixelModal
  },
  data () {
    return {
      reload: false
    }
  },
  metaInfo () {
    return {
      title: this.$t('pixels')
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  },
  methods: {
    /**
     * Handle upsert
     */
    handleUpsert (pixel) {
      this.reloadPixels()
    },
    /**
     * Reload pixels
     */
    reloadPixels () {
      this.reload = true
      setTimeout(() => {
        this.reload = false
      }, 300)
    }
  }
}
</script>
